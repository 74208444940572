import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Stage from '../components/Stage';
import Section from '../components/Section';
import Container from '../components/Container';
import List from '../components/List';
import Intro from '../components/Intro';
import { P, Headline } from '../components/Typography';
import ContactTeaser from '../components/ContactTeaser';
import PersonBox from '../components/PersonBox';

import { getImageFromNode } from '../helpers/utils';

/** Bild im Rechts-Links-Wechsel */
const Img = styled(GatsbyImage)`
    width: 100%;
`;

const AboutUsPage = ({ data }) => {
    const { stageImage, contentImages, personBoxImages } = data;
    return (
        <Layout title="Über uns" description="Schütz Dienstleistungen - Über uns">
            <Stage image={stageImage.childImageSharp} title="Über uns" />
            <Section>
                <Container size="l" grid cols="2" alignItems="center" reverseOnMobile>
                    <Intro headline="Wir sind Schütz Dienstleistungen">
                        <P>
                            {/* eslint-disable */}
                            Wir sind ihr kompetenter und professioneller Partner für eine Vielzahl
                            von Dienstleistungen rund ums Haus. Mit unser über{' '}
                            {` ${new Date().getFullYear() - 1992}`}-jährigen Erfahrung und
                            zahlreichen Zertifizierungen stehen wir gerne auch Ihnen tatkräftig bei
                            individuellen Anforderungen und Wünschen zur Seite.
                            {/* eslint-enable */}
                        </P>
                    </Intro>
                    <Img
                        fluid={
                            getImageFromNode(contentImages, 'about-us/contentimage-intro.jpg').node
                                .childImageSharp.fluid
                        }
                    />
                </Container>
            </Section>
            <Section bg="gray" container="l">
                <Headline level="h2" gap="l">
                    Daten & Fakten
                </Headline>
                <List
                    items={[
                        'Firmengründung 1992 in Stuttgart',
                        'Mitglied in der Handwerkskammer & im Europäischen Fachverband für Desinfektoren e.V.',
                        'Seit 1992 Gebäudereinigungsmeister',
                        'Seit 1998 staatlich geprüfter Desinfektor (Zertifiziert gem. der ISO/IEC EN 17024)',
                        'Befähigungsnachweis für die praxisgerechte Umsetzung der Lebensmittelhygiene',
                        'Seit 2005 Sachkundenachweis im Pflanzenschutz (für die Anwendung u. Abgabe von Pflanzenschutzmitteln)',
                        'Seit 2006 IHK - geprüfter Schädlingsbekämpfer',
                    ]}
                    showBullets
                />
            </Section>
            <ContactTeaser />
            {/* <Section>
                <Headline level="h2" gap="xl" textAlign="center">
                    Team
                </Headline>
                <Container size="l" grid cols="3">
                    <PersonBox
                        name="Peter Schütz"
                        title="Betriebsinhaber, Geschäftsführer"
                        image={
                            getImageFromNode(
                                personBoxImages,
                                'about-us/personboximage-peter-schuetz.jpg'
                            ).node.childImageSharp
                        }
                    />
                    <PersonBox
                        name="Marlies Schütz"
                        title="Buchhaltung"
                        image={
                            getImageFromNode(
                                personBoxImages,
                                'about-us/personboximage-marlies-schuetz.jpg'
                            ).node.childImageSharp
                        }
                    />
                    <PersonBox
                        name="Luca ?"
                        title="Gebäudereinigung"
                        image={
                            getImageFromNode(personBoxImages, 'about-us/personboximage-luca.jpg')
                                .node.childImageSharp
                        }
                    />
                </Container>
            </Section> */}
        </Layout>
    );
};

AboutUsPage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 *
 *    personBoxImages: allFile(
            filter: { relativePath: { glob: "about-us/personboximage-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    ...PersonBoxFluid
                }
            }
        }
 */
export const query = graphql`
    {
        stageImage: file(relativePath: { glob: "about-us/stage-image.jpg" }) {
            ...StageFluid
        }
        contentImages: allFile(
            filter: { relativePath: { glob: "about-us/contentimage-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 585, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default AboutUsPage;
